/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info-circle': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<circle pid="0" _stroke="red" _fill="none" cx="7" cy="7" r="6.5"/><path pid="1" _stroke="red" _fill="#00514A" stroke-linecap="square" d="M7 7v2"/><path pid="2" _fill="red" d="M6.5 4.5h1v1h-1z"/>'
  }
})
